.deals-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.dealsUp {
  display: flex;
  justify-content: space-between;
}
.card {
  flex-basis: calc(33.33% - 20px);
  margin-right: 20px;
  max-width: 100%;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card img {
  width: 100%;
  height: auto;
  transition: all 0.2s linear;
  /* margin-bottom: 10px; */
}
.card img:hover {
  transform: scale(1.1);
}
.card h1 {
  font-size: 18px;
  margin-bottom: 20px;
  color: black;
}

.card p {
  font-size: 14px;
  color: #555;
}

.inside-text {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}

.inside-text button {
  transition: all 0.2s linear;
  border-radius: 1px solid grey;
}

.inside-text button:hover {
  transform: scale(1.1);
  background-color: rgb(97, 97, 97);
  color: white;
}
@media (max-width: 768px) {
  .deals-container {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  .dealsUp {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .card {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .inside-text {
    flex-direction: column;
    align-items: flex-start;
  }

  .inside-text button {
    margin-top: 10px;
  }
}
