.form {
  width: 30%;
  margin: 0 auto;
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  width: 100%;
  padding: 6px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
}

button.btn2 {
  display: block;
  margin-top: 12px;
  width: 100%;
  padding: 12px;
  font-size: 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3ms all ease-in-out;
}

button.btn2:hover {
  transform: scale(1.1);
  background-color: #bbbbbb;
}
@media (max-width: 768px) {
  .form {
    width: 80%;
    padding: 10px;
    border-radius: 8px;
  }

  label {
    margin-bottom: 6px;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    padding: 4px;
    margin-bottom: 8px;
    font-size: 10px;
  }

  button.btn2 {
    padding: 8px;
    font-size: 10px;
    border-radius: 4px;
  }
}

@media (max-width: 480px) {
  .form {
    width: 90%;
    padding: 8px;
    border-radius: 6px;
  }

  label {
    margin-bottom: 4px;
    font-size: 10px;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    padding: 2px;
    margin-bottom: 6px;
    font-size: 8px;
  }

  button.btn2 {
    padding: 6px;
    font-size: 8px;
    border-radius: 4px;
  }
}
