* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.home,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.deals {
  background-image: url("/public/images/imgs-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url("/public/images/imgs-1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}

.sign-up {
  background-image: url("/public/images/imgs-8.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* color: #fff; */
  font-size: 25px;
}

::-webkit-scrollbar {
  width: 7px;
  background-color: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(28, 108, 128);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a3a3a3;
}
